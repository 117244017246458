export const dynamicScaleComponent = {
    props: {
        fieldsPermissions:{type: Object},
        data:{type: Object},
        isClone:{type: Boolean},
        scaleName:{type: String},
        scaleConfig:{type: Object},
        disabled: {
            type: Boolean,
            default: false
        }
    },

    watch: {
        data: function() {
            this.fillScaleFromData();
        }
    },

    mounted() {
        this.fillScaleFromData();
    },

    methods: {
        fillScaleFromData(){
            for (const [key, value] of Object.entries(this.scale)) {
                this.scale[key] = this.data ? this.data[key] : null;
            }
            this.afterFillScaleFromData();
        },
        afterFillScaleFromData(){},
    }
}